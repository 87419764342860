import { holesky } from "viem/chains";

export const projectId = "7e004e9cfa01b95768e4da0375b55f45";

export const APIURL = {
  BATCH_LIST: "/admin/batchList", 
  BATCH_LIST_WITH_ID: "/admin/checkBatches",
  DEPOSIT_BATCH_LIST: "/admin/addAmount", 
  UPDATE_STATUS:"admin/updateStatus",

};

let API_HOST;
let CHAIN_ID;
let DEFAULT_CHAIN_DATA;
let SITE_URL;
let ALLOWED_CHAIN_ID;
let ALLOWED_CHAIN_NAME;
let SCAN_EXPLORER;
let DAPP_URL_METAMASK;
let DAPP_URL_TRUSTWALLET;
let DAPP_URL_COINBASE;
let USDT_TOKEN_ADDRESS;
let MINIMUM_TRANSACTION_LIMIT;
let REFRESH_TOKEN_TIMEOUT;
let ASSEST_ID;
let EXPLORER_LINK;
let TXN_FILTER_MIN_DATE;
let INTERVAL_TIMER;
let USDC_TOKEN_DECIMALS;
let RPC_URL;
let CHRIS_CONTRACT_ADDRESSS;
let WEI;

RPC_URL = "https://ethereum-holesky-rpc.publicnode.com";
API_HOST = "https://stage-ethaearn-api.staging-host.com";

CHAIN_ID = 17000;
DEFAULT_CHAIN_DATA = holesky;
ALLOWED_CHAIN_ID = 17000;
ALLOWED_CHAIN_NAME = "holskey";
SCAN_EXPLORER = "http://localhost:3000";
DAPP_URL_METAMASK = "http://localhost:3000";
DAPP_URL_TRUSTWALLET = "http://localhost:3000";
USDT_TOKEN_ADDRESS = "";
MINIMUM_TRANSACTION_LIMIT = 0.0001;
CHRIS_CONTRACT_ADDRESSS = "0xAAA59700B11640e5Bc02577b1FEaF427De488f5c"

USDC_TOKEN_DECIMALS = 18;
WEI = 10**  18;
// }

export const LIMIT = 10;
export const LENGTH = 128;

//exporting all the variables
export { CHAIN_ID };
export { DEFAULT_CHAIN_DATA };
export { SITE_URL };
export { ALLOWED_CHAIN_ID };
export { ALLOWED_CHAIN_NAME };
export { SCAN_EXPLORER };
export { DAPP_URL_METAMASK };
export { DAPP_URL_TRUSTWALLET };
export { DAPP_URL_COINBASE };
export { API_HOST };
export { MINIMUM_TRANSACTION_LIMIT };
export { REFRESH_TOKEN_TIMEOUT };
export { ASSEST_ID };
export { EXPLORER_LINK };
export { TXN_FILTER_MIN_DATE };
export { INTERVAL_TIMER };
export { RPC_URL };
export { CHRIS_CONTRACT_ADDRESSS };
export { WEI };

export const WALLET_TYPE = {
  METAMASK: "MetaMask",
  WALLET_CONNECT: "WalletConnect",
};

export const selectedNetwork = [
  // TEST_NET
  {
    symbol: "ETH",
    value: "Holesky",
    label: "Holesky",
    chainId: 17000,
    chainidHex: 0x4268,
    rpc: "https://ethereum-holesky-rpc.publicnode.com",
    explorerUrl: "https://holesky.etherscan.io/",
    decimals: 18,
  },
];
export const WALLET_IDS = {
  // REFERENCE : https://explorer.walletconnect.com/
  METAMASK: "c57ca95b47569778a828d19178114f4db188b89b763c899ba0be274e97267d96",
  TRUSTWALLET:
    "4622a2b2d6af1c9844944291e5e7351a6aa24cd7b23099efac1b2fd875da31a0",
  COINBASE: "fd20dc426fb37566d803205b19bbc1d4096b248ac04548e3cfb6b3a38bd033aa",
  SUBWALLET: "9ce87712b99b3eb57396cc8621db8900ac983c712236f48fb70ad28760be3f6a",
};
