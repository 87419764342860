import { Col, Container, Row, ToastContainer } from "react-bootstrap";
import { Link } from "react-router-dom";
import "./Header.scss";
import LogoSmall from "../../../assets/images/logo.png";
import { HandIcon } from "../../../assets/SvgIcons/SvgIcons";
import CommonModal from "../Ui/CommonModal/CommonModal";
import { useEffect, useState } from "react";
import CommonButton from "../CommonButton/CommonButton";
import { useDispatch } from "react-redux";
import {
  resetUserDataSlice,
  setIsLoggedIn,
  setUserWalletAddress,
} from "../../../redux/userData/userData";
import { useAccount, useDisconnect } from "wagmi";
import { useNetwork } from "wagmi";
import { useSwitchNetwork } from "wagmi";
import { useWeb3Modal } from "@web3modal/wagmi/react";
import toast, { Toaster } from "react-hot-toast";
import { callContractGetMethod } from "../../../services/contract.action";

const Header = ({ toggleClick, toggleClassName }) => {
  const dispatch = useDispatch();
  const [show, setShow] = useState(false);
  const { chain } = useNetwork();
  const { switchNetwork } = useSwitchNetwork();
  const { open } = useWeb3Modal();
  const { address, isConnected } = useAccount();
  const { disconnect: resetConfig } = useDisconnect();
  const [ownerAddress, setOwnerAddress] = useState("");

  useEffect(() => {
    if (isConnected && chain?.id !== 17000) {
      switchNetwork?.(17000);
    }
    if (isConnected) {
      getOwner();
      if(ownerAddress?.toLowerCase() === address.toLowerCase()){
        dispatch(setUserWalletAddress(address));
      }else{
        toast.error("Unauthorized wallet. Please connect with the admin's wallet.");
        walletDisconnect();
      }
    }
  }, [isConnected, chain?.id, switchNetwork]);

  const handleConnect = async () => {
    
    await open();
  };

  const walletDisconnect = async () => {
    resetConfig();
    dispatch(setIsLoggedIn(false));
    dispatch(setUserWalletAddress(""));
    dispatch(resetUserDataSlice());
  };

  const getOwner = async () => {
    let ownerAddress = "";
    try {
      ownerAddress = await dispatch(callContractGetMethod("owner", []));
      setOwnerAddress(ownerAddress);
      return ownerAddress; // Return the value
    } catch (error) {
      console.error("Error getting pooled ETH by shares:", error);
    }
  };

  return (
    <>
      <div className="header">
        <Container fluid>
          <Row className="align-items-center">
            <Col xs={4} lg={5} className="header_welcome">
              <Link className="header_logo" to="#">
                <img src={LogoSmall} alt="" />
              </Link>
              <h6>
                Welcome Back
                <br /> Admin <HandIcon />
              </h6>
            </Col>
            <Col className="ps-0" xs={8} lg={7}>
              <div className="header_actions d-flex align-items-center">
                {address && (
                  <div className="wallet-info me-3">
                    <span
                      style={{ color: "black" }}
                      className="clickable-address"
                    >
                      {address.slice(0, 7)}...{address.slice(-4)}
                    </span>
                  </div>
                )}
                <CommonButton
                  title={isConnected ? "Disconnect" : "Connect Wallet"}
                  onClick={isConnected ? walletDisconnect : handleConnect}
                  className="me-3"
                />
                <button
                  className={`header_actions_toggle ${toggleClassName}`}
                  onClick={toggleClick}
                >
                  <span className="menu-line"></span>
                  <span className="menu-line"></span>
                  <span className="menu-line"></span>
                </button>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
      <Toaster position="top-right" reverseOrder={false} />
      <CommonModal show={show} onHide={() => setShow(false)} title="Logout">
        <p className="text-center py-5">Are you sure you want to Logout?</p>
        <div className="border-top pt-4 mt-5">
          <Row>
            <Col>
              <CommonButton
                title="No"
                className="w-100 border-btn"
                onClick={() => setShow(false)}
              />
            </Col>
            <Col>
              <CommonButton
                title="Yes"
                className="w-100"
                onClick={() => setShow(false)}
                role="link"
                to="/"
              />
            </Col>
          </Row>
        </div>
      </CommonModal>
    </>
  );
};

export default Header;
