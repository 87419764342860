import Multiselect from "multiselect-react-dropdown";
import { Col, Form, Modal, Row, Spinner } from "react-bootstrap";
import CommonButton from "../../../Common/CommonButton/CommonButton";
import CommonPagination from "../../../Common/CommonPagination/CommonPagination";
import SelectedTokenTable from "../../../Common/SelectedTokenTable/SelectedTokenTable";
import "./Dashboard.scss";
import BigNumber from "bignumber.js";
import {
  getBatchList,
  getBatchListDeposit,
  getBatchListWithId,
  updateStatus,
} from "../../../../Api/user.action";
import { useDispatch, useSelector } from "react-redux";
import {
  callContractGetMethod,
  callContractSendMethod,
} from "../../../../services/contract.action";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { WEI } from "../../../../constants";
import FullPageLoader from "../../../Common/loader/FullPageLoader";
import { setSelectedBatchForAdmin } from "../../../../redux/userData/userData";
// import BigNumber from "bignumber.js";

const Dashboard = () => {
  const [batchData, setBatchData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [batchId, setBatchId] = useState([]);
  const [inputCheck, setInputCheck] = useState(false);
  const [selectedBatch, setSelectedBatch] = useState(["NO option Available"]);
  const [amount, setAmount] = useState("");
  const [isDepositEnabled, setIsDepositEnabled] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showDepositForPageButton, setShowDepositForPageButton] =
    useState(false);
  const [data, setData] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [amountForPool, setAmountForPool] = useState("");
  const [totalAmountInWeiForPage, setTotalAmountInWeiForPage] = useState("");

  const { userWalletAddress, selectedBatchForAdmin: selectedBatchByAdmin } =
    useSelector((state) => state.userDataSlice);
  const dispatch = useDispatch();
  const batchList = async (
    batchId = selectedBatch,
    limit = 10,
    page = currentPage,
    showDepositsInfo
  ) => {
    try {
      setIsLoading(true);
      const result = await getBatchList(dispatch, batchId, limit, page);
      if (result?.status === 200) {
        setIsLoading(false);
        setBatchData(result?.data);
        setCurrentPage(result?.currentPage);
        setTotalPages(Math.ceil(result?.totalCount / limit));
        const walletAddresses = result.data.map((item) => item.walletAddress);
        const amountsInWei = result.data.map((item) => item.amount);
        const msgValue = result.data.map((item) => item.msgValue);
        const totalAmountInWei = msgValue.reduce(
          (sum, totalAmount) => sum.plus(new BigNumber(totalAmount)),
          new BigNumber(0)
        );
        setTotalAmountInWeiForPage(totalAmountInWei.dividedBy(WEI).toString());
        if (!result.allowance && !showDepositsInfo) {
          setInputCheck(true);
          setAmount(totalAmountInWei / WEI);
          setShowModal(true);
          setShowDepositForPageButton(true);
        } else {
          setInputCheck(false);
          setShowDepositForPageButton(false);
        }
        const ids = result.data.map((item) => item._id);
        const totalAmount = result.data.map((item) => item.amount);

        setData({
          wallets: walletAddresses,
          amounts: amountsInWei,
          ids,
          totalAmount: totalAmount,
          msgValue: msgValue,
          totalPages: result.totalPages,
        });
      }
    } catch (error) {
      setIsLoading(false);
      console.error(error);
    }
  };
  const batchListID = async () => {
    try {
      const result = await getBatchListWithId(dispatch);
      if (result?.status === 200) {
        const batchOptions = result?.data.map((id) => ({ key: id, value: id }));
        setBatchId(batchOptions);
        const isBatchIdInOptions = batchOptions.some(
          (batch) => batch.key === selectedBatchByAdmin?.[0]?.key
        );

        if (isBatchIdInOptions) {
          setSelectedBatch([selectedBatchByAdmin[0]]);
        } else {
          // Set default batch to the first item from batchOptions if not available in Redux
          const defaultBatch = batchOptions[0];
          if (defaultBatch) {
            setSelectedBatch([defaultBatch]);
            dispatch(setSelectedBatchForAdmin([defaultBatch]));
          }
        }
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    batchListID(); // This API should only run once on component mount
  }, []);

  useEffect(() => {
    if (selectedBatch[0]?.key > 0) {
      setAmount("");
      setBatchData([]);
      batchList(selectedBatch[0]?.key, 10, currentPage, false); // Fetch batch list when selected batch changes or on initial load
    }
  }, [selectedBatch, currentPage]);

  // Function to handle page change
  const handlePageChange = (page) => {
    setCurrentPage(page);
  };
  const getShareByPool = async (value) => {
    let shareDeposit = "";
    try {
      shareDeposit = await dispatch(
        callContractGetMethod("getSharesByPooledEth", [
          BigNumber(value) * BigNumber(WEI),
        ])
      );
      return shareDeposit.toString(); // Return the value
    } catch (error) {
      console.error("Error getting shares by pooled ETH:", error);
    }
  };

  const getPoolByShare = async (shareValue) => {
    let shareDepositForPool = "";
    try {
      shareDepositForPool = await dispatch(
        callContractGetMethod("getPooledEthByShares", [shareValue])
      );
      return shareDepositForPool.toString(); // Return the value
    } catch (error) {
      console.error("Error getting pooled ETH by shares:", error);
    }
  };

  const depositAmount = async () => {
    try {
      // Now that amount is set with the final value, proceed with deposit
      const result = await getBatchListDeposit(
        dispatch,
        (amount * WEI).toString(),
        selectedBatch[0].key,
        amountForPool
      );
      if (result?.status === 200) {
        setShowModal(true);
        batchList(selectedBatch[0].key, 10, currentPage, true);
      }
    } catch (error) {
      console.error(error);
    }
  };
  const updateStatusForStatus = async () => {
    try {
      const result = await updateStatus(
        dispatch,
        data.wallets,
        selectedBatch[0].key
      );
      if (result?.status === 200 && result.totalPages > 1) {
        setShowModal(true);
        batchList(selectedBatch[0].key, 10, currentPage);
      }
    } catch (error) {
      console.error(error);
    }
  };
  useEffect(() => {
    setIsDepositEnabled(userWalletAddress && parseFloat(amount) > 0.0);
  }, [userWalletAddress, amount]);

  const handleAmountChange = async (e) => {
    if (!userWalletAddress) return;
    const value = e.target.value;

    setAmount(value);

    // Trigger contract method call when the amount is changed
    if (value !== "") {
      try {
        const shareValue = await getShareByPool(value);

        if (shareValue) {
          const pooledAmount = await getPoolByShare(shareValue);

          // Now you can pass this pooledAmount to the deposit function
          if (pooledAmount) {
            setAmountForPool(pooledAmount);
          }
        }
      } catch (error) {
        console.error("Error fetching contract data:", error);
      }
    }
  };
  const handleCloseModal = () => {
    setShowModal(false);
  };

  const depositToken = async (
    walletAddresses = data.wallets,
    amountsInWei = data.amount,
    ids = data.ids,
    msgValue = data.msgValue
  ) => {
    if (!userWalletAddress) {
      toast.error("Wallet is not connected.");
      return;
    }
    try {
      setIsLoading(true);
      const totalAmountInWeiForPage = data.msgValue.reduce(
        (sum, totalAmount) => sum.plus(new BigNumber(totalAmount)),
        new BigNumber(0)
      );

      let result = await dispatch(
        callContractSendMethod(
          "deposit",
          [walletAddresses, amountsInWei, ids],
          userWalletAddress,
          totalAmountInWeiForPage.toString()
        )
      );

      if (result?.transactionHash) {
        toast.success("Deposit successful!");
        handleCloseModal();
        await updateStatusForStatus();
        setCurrentPage(1);
        await batchList(selectedBatch[0].key, 10, currentPage);
        // }
        await batchListID();
      }
    } catch (error) {
      handleCloseModal();
    } finally {
      setIsLoading(false);
    }
  };
  const handleBatchChange = (selectedList) => {
    setSelectedBatch(selectedList);
    setCurrentPage(1); // Reset page to 1 when switching batches
    dispatch(setSelectedBatchForAdmin(selectedList));
    batchList(selectedList[0]?.key, 10, 1, false); // Fetch batch data for page 1
  };
  const handleInputKeyDown = (e) => {
    // Prevent up and down arrow scrolling
    if (["ArrowUp", "ArrowDown"].includes(e.key)) {
      e.preventDefault();
    }

    // Prevent invalid characters like "e", "E", "+", "-"
    if (["e", "E", "+", "-"].includes(e.key)) {
      e.preventDefault();
    }
  };

  const handleWheel = (e) => {
    e.preventDefault(); // Prevents mouse wheel scrolling
  };

  return (
    <>
      {isLoading && <FullPageLoader />}
      <div className="dashboard_page">
        <Row className="pb-5 align-items-center">
          <Col md={5}>
            <h1>Rewards Details</h1>
          </Col>
          <Col md={7} className="dashboard_page_action">
            <Multiselect
              className="selectbox"
              displayValue="key"
              onKeyPressFn={function noRefCheck() {}}
              onRemove={function noRefCheck() {}}
              onSearch={function noRefCheck() {}}
              onSelect={handleBatchChange}
              avoidHighlightFirstOption={true}
              placeholder={batchId?.length ? "Select Batch" : "No Batch"}
              options={batchId}
              singleSelect
              selectedValues={batchId?.length ? selectedBatch : []}
              disable={batchId?.length == 0}
              customCloseIcon={" "}
            />
            <div className="csv">
              <input className="d-none" type="file" id="csv" />
            </div>
          </Col>
        </Row>
        <Row className="align-items-center">
          <Col xs={12}>
            <SelectedTokenTable
              batchData={batchData}
              currentPage={currentPage}
              itemsPerPage={10}
            />

            {isLoading && <FullPageLoader />
              ? null
              : batchData?.length === 0 && (
                  <div style={{ color: "black" }} className="no-data-available">
                    <p>No Data Available</p>
                  </div>
                )}
          </Col>
          <Col md={6} className="pt-5">
            {userWalletAddress && batchData?.length > 0 && (
              <Row>
                <Col sm={8}>
                  {!inputCheck && (
                    <Form.Control
                      type="number"
                      placeholder="Enter amount"
                      value={amount}
                      onChange={handleAmountChange}
                      onKeyDown={handleInputKeyDown}
                      onWheel={handleWheel}
                    />
                  )}
                </Col>
                <Col>
                  {!inputCheck && (
                    <CommonButton
                      title="Deposit"
                      className="w-100 mt-3 m-sm-0"
                      onClick={depositAmount}
                      disabled={!isDepositEnabled}
                    />
                  )}
                </Col>
              </Row>
            )}
          </Col>
          <Col md={6} className="pt-5 text-center text-md-end">
            {totalPages > 1 && (
              <CommonPagination
                currentPage={currentPage}
                totalPages={totalPages}
                onPageChange={handlePageChange}
              />
            )}
          </Col>
        </Row>
      </div>
      {userWalletAddress && showDepositForPageButton && (
        <Row className="pt-3">
          <Col md={12} className="text-center">
            <CommonButton
              // className="w-100 mt-3 m-sm-0"
              title="Deposit"
              onClick={() => setShowModal(true)}
            />
          </Col>
        </Row>
      )}
      {userWalletAddress && (
        <Modal show={showModal} onHide={handleCloseModal}>
          <Modal.Header closeButton>
            <Modal.Title>Want to Deposit?</Modal.Title>
          </Modal.Header>

          <Modal.Body>
            {!inputCheck
              ? `Your deposit of ${
                  String(amount).startsWith(".") ? `0${amount}` : amount
                } ETH is being processed for this batch.`
              : "Desposit for this Batch is already initialized"}
            <br />
            {`Total Amount For Current Page: ${
              totalAmountInWeiForPage || "Calculating..."
            } ETH`}
          </Modal.Body>
          <Modal.Footer>
            <CommonButton
              title="Yes"
              onClick={() => {
                if (userWalletAddress) {
                  depositToken(data?.wallets, data?.amounts, data?.ids);
                } else {
                  handleCloseModal();
                  toast.error("Please connect your wallet to proceed.");
                }
              }}
            />
          </Modal.Footer>
        </Modal>
      )}
    </>
  );
};
export default Dashboard;
